import { memo } from 'react';
import Image from 'next/image';
import StyledContainer from '@src/components/StyledContainer';
import { useI18n } from '@src/locales';
import StyledFoot from './foot.styled';

const Foot = () => {
    const { t } = useI18n();

    return (
        <StyledFoot className="foot">
            <StyledContainer>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="secure">
                        <Image
                            src={`${process.env.NEXT_PUBLIC_CDN_IMAGES}layout/secure.png`}
                            alt=""
                            width={284}
                            height={49}
                        />
                    </div>
                    <div className="pt-1 px-0 payment">
                        <span>{t('securePayment')}</span>
                        <Image
                            src={`${process.env.NEXT_PUBLIC_CDN_IMAGES}layout/payment.png`}
                            alt=""
                            width={415}
                            height={38}
                        />
                    </div>
                </div>
            </StyledContainer>
        </StyledFoot>
    );
};

export default memo(Foot);
