import styled from 'styled-components';
import { em } from 'polished';

const StyledFoot = styled.div`
    padding: 1em 0;

    @media (max-width: 767px) {
        .col-0.text-right:last-of-type {
            text-align: left !important;
        }
    }
    
    span {
        display: block;
        font-size: ${em('14px')};
    }

    img {
        width: 100%;
        display: inline-block;
        transition: all .2s;
    }
`;

export default StyledFoot;
